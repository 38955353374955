/* eslint-disable max-len */
import React from 'react'

const ArrowDown = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={11}
    viewBox="0 0 18 11"
    {...props}
  >
    <path d="M16 2L9 9" strokeWidth="2" strokeLinecap="square"/>
    <path d="M2 2L9 9" strokeWidth="2" strokeLinecap="square"/>
  </svg>
)

export default ArrowDown
