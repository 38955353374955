import { useContext, useEffect } from 'react'
import { StoreContext } from '@/store'


const RouteWrapper = ({ children, ...props }) => {
  const store = useContext(StoreContext)

  useEffect(() => {
    store.layoutStore.set(props)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [children])

  return children
}

export default RouteWrapper
