import { useEffect } from 'react'

const useOnScrollToEnd = (ref, callback) => {
  useEffect(() => {
    let currentRef = ref.current
    const handleScroll = () => {
      if (currentRef) {
        const { scrollTop, scrollHeight, clientHeight } = currentRef
        if (scrollTop + clientHeight >= scrollHeight - 2) {
          callback()
        }
      }
    }

    if (currentRef) {
      currentRef.addEventListener('scroll', handleScroll)
    }

    return () => {
      if (currentRef) {
        currentRef.removeEventListener('scroll', handleScroll)
      }
    }
  }, [ref, callback])
}

export default useOnScrollToEnd
