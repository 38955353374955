import classNames from 'classnames'
import { Link } from 'react-router-dom'

import styles from './index.module.css'
import { BUTTON_TYPES, BUTTON_COLORS } from './constants'

const Button = ({
  className,
  type = BUTTON_TYPES.fill,
  color = BUTTON_COLORS.main,
  disabled,
  link,
  children,
  ...otherProps
}) => {

  const classes = classNames(
    styles.root,
    styles[type],
    !link && disabled && styles.disabled,
    styles[color],
    className
  )

  return (
    <>
      {children && link &&
        <Link
          {...otherProps}
          to={link}
          className={classes}
        >
          {children}
        </Link>
      }
      {children && !link &&
        <button
          {...otherProps}
          type="button"
          className={classes}
          disabled={disabled}
        >
          {children}
        </button>
      }
    </>
  )
}

export default Button

export { BUTTON_TYPES, BUTTON_COLORS } from './constants'
