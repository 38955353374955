import React from 'react'

const DotMenu = (props) => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    strokeWidth={0}
  >
    <circle cx="11" cy="2" r="2" />
    <circle cx="11" cy="11" r="2" />
    <circle cx="11" cy="20" r="2" />
  </svg>
)

export default DotMenu
