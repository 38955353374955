export const hasErrors = errors => {
  if (errors === undefined || errors === null) return false
  if (typeof errors === 'object') {
    return Object.keys(errors).reduce((memo, key) => {
      const error = errors[key]
      if (error === undefined || error === null || error === false) return memo || false
      if (typeof error === 'object') return memo || hasErrors(error)
      return true
    }, false)
  }
  return true
}

const isArrayAsObject = (obj = {}) => {
  return !!Object.keys(obj).length && Object.keys(obj).every(item => !Number.isNaN(+item))
}

export const normalize = obj => {
  if (obj && typeof obj === 'object') {
    if (Array.isArray(obj)) {
      const array = []
      for (let i = 0; i < obj.length; i += 1) {
        array.push(normalize(obj[i]))
      }
      return array
    } else if (isArrayAsObject(obj)) {
      const array = []
      const length = Math.max(...Object.keys(obj)) + 1
      for (let i = 0; i < length; i += 1) {
        array.push(normalize(obj[i]))
      }
      return array
    } else {
      return Object.entries(obj).reduce((memo, [key, value]) => {
        return {
          ...memo,
          [key]: normalize(value),
        }
      }, {})
    }
  }
  return obj
}

export const getPaths = (obj, root) => {
  return Object.entries(obj || {}).reduce((memo, [key, value]) => {
    const path = [root, key].filter(Boolean).join('.')
    if (typeof value === 'object') {
      return [
        ...memo,
        ...getPaths(value, path),
      ]
    }
    return [...memo, path]
  }, [])
}
