import classNames from 'classnames'

import styles from './index.module.css'

const Table = ({
  className,
  data,
  head,
  body = [],
}) => {
  return (
    <div className={styles.wrapper1}>
      <div className={styles.wrapper2}>
        <table className={classNames(className, styles.root)}>
          {!!head?.length && (
            <thead>
              <tr>
                {head.map(({ title, ...props }, i) => (
                  <th key={i} {...props}>{title}</th>
                ))}
              </tr>
            </thead>
          )}
          <tbody>
            {data.map((item, i) => (
              <tr key={i}>
                {body.map(({ model = () => null, ...props }, j) => (
                  <td key={j} {...props}>
                    {model(item, i)}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default Table
