import { toPhone, fromPhone, checkPhoneFormat, checkMailFormat } from '@/utils/helpers/format'

import { INCORRECT_PHONE_FORMAT, INCORRECT_MAIL_FORMAT } from '@/constants'


export const TYPE_TO_TYPE = {
  text: 'text',
  password: 'password',
  phone: 'text',
  mail: 'text',
}

export const TYPE_TO_FORMAT = {
  phone: toPhone,
}

export const TYPE_TO_UNFORMAT = {
  phone: fromPhone,
}

export const TYPE_TO_VALIDATE = {
  phone: {
    isValid: checkPhoneFormat,
    error: INCORRECT_PHONE_FORMAT,
  },
  mail: {
    isValid: checkMailFormat,
    error: INCORRECT_MAIL_FORMAT,
  },
}
