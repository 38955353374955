import { useState, useRef } from 'react'
import useClickOutside from '@/utils/hooks/useClickOutside'
import classNames from 'classnames'
import styles from './index.module.css'

const DropDownList = ({
  isHoverable = false,
  hasBorder = false,
  hasBackground = true,
  sideToOpen = 'down',
  minHeight,
  HeadComponent,
  children,
}) => {
  const listRef = useRef()
  const childrenRef = useRef()
  const [active, toggleActive] = useState(false)

  useClickOutside(listRef, () => {
    toggleActive(false)
  })

  const clickHandle = (e) => {
    if (!isHoverable && e.target !== childrenRef.current) {
      toggleActive(!active)
    }
  }

  const hoverHandle = (bool) => {
    if (isHoverable) {
      toggleActive(bool)
    }
  }

  return (
    <div className={classNames(
      styles.list,
      isHoverable && styles.hover,
      active && styles.active,
      hasBackground && styles.background
    )}
    ref={listRef}
    onClick={clickHandle}
    onMouseEnter={() => hoverHandle(true)}
    onMouseLeave={() => hoverHandle(false)}>
      {HeadComponent && (
        <div className={styles.head}>
          <HeadComponent isActive={active}/>
        </div>
      )}

      <div className={classNames(
        styles.children,
        hasBorder && styles.border,
        sideToOpen && styles[sideToOpen]
      )}
      ref={childrenRef}
      style={{ height: minHeight }}>
        {children}
      </div>
    </div>
  )
}

export default DropDownList
