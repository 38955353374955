import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import { useContext } from 'react'
import { observer } from 'mobx-react-lite'
import { StoreContext } from './store'
import getRoutes from './Routes'
import ModalsComponent from './modals'

const App = () => {
  const store = useContext(StoreContext)
  const { authStore } = store
  const routes = getRoutes(authStore)
  const router = createBrowserRouter(routes)

  return (
    <>
      <ModalsComponent />
      <RouterProvider router={router}/>
    </>
  )
}

export default observer(App)
