const STORE_NAME = 'ECO_STORE'


export const getStoreInitData = () => {
  const store = window.localStorage.getItem(STORE_NAME)
  if (!store) return undefined
  try {
    return JSON.parse(store)
  } catch {
    return undefined
  }
}

export const setStoreInitData = (store) => {
  if (store) {
    try {
      window.localStorage.setItem(STORE_NAME, JSON.stringify(store))
    } catch {}
  }
}
