/* eslint-disable max-len */
import React from 'react'

const Mark = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={12}
    height={10}
    viewBox="0 0 12 10"
    {...props}
  >
    <path d="M1 4.29412L5 8L11 1" strokeWidth="2" fill="none" />
  </svg>
)

export default Mark
