import { observer } from 'mobx-react-lite'
import { useContext } from 'react'

import { StoreContext } from '@/store'

import Table from '@/components/Table'
import Button, { BUTTON_TYPES, BUTTON_COLORS } from '@/components/Button'
import DropDownList from '@/components/DropDownList'
import Icon, { ICONS_TYPES } from '@/components/Icon'

import { ROLE_DICT } from '@/constants'

import pageStyles from '@/styles/page.module.css'
import styles from './index.module.css'


const Root = () => {
  const store = useContext(StoreContext)

  const { httpStore, formsStore } = store

  const usersArgs = [
    'listUsers',
    ['id', 'email', 'name', 'role', { division: ['id', 'name'] }],
    undefined,
  ]

  const users = httpStore.fetchRequest(...usersArgs).get('data', [])

  return (
    <div className={pageStyles.page}>
      <div className={pageStyles.header}>
        <h1 className={pageStyles.title}>
          Управление пользователями
        </h1>
        <div className={pageStyles.headerControls}>
          <Button
            type={BUTTON_TYPES.dashed}
            color={BUTTON_COLORS.blue}
            onClick={() => {
              const formName = 'createUser'
              formsStore.createForm(formName, {
                modalComponent: 'UserEditModal',
                props: {
                  onSuccess: () => httpStore.fetchRequest(...usersArgs, {
                    cacheTime: 0,
                  }),
                },
              })
            }}
          >
            <span>+ Создать пользователя</span>
          </Button>
        </div>
      </div>
      <div className={pageStyles.block}>
        <Table
          className={styles.table}
          data={users}
          head={[
            {
              title: 'ID',
              className: styles.small,
            },
            {
              title: 'E-Mail',
            },
            {
              title: 'Имя',
            },
            {
              title: 'Роль',
            },
            {
              title: 'Подразделение',
            },
            {
              className: styles.dotMenu,
            },
          ]}
          body={[
            {
              model: item => item.id,
            },
            {
              model: item => item.email,
            },
            {
              model: item => item.name,
            },
            {
              model: item => ROLE_DICT[item.role] || item.role,
            },
            {
              model: item => item.division?.name,
            },
            {
              className: styles.dotMenu,
              model: item => (
                <DropDownList
                  hasBackground={false}
                  hasBorder
                  sideToOpen="left"
                  HeadComponent={({ isActive }) => (
                    <Icon
                      className={isActive && styles.dotMenuActive}
                      type={ICONS_TYPES.dotMenu}
                    />
                  )}
                >
                  <Button
                    type={BUTTON_TYPES.text}
                    color={BUTTON_COLORS.blue}
                    onClick={() => {
                      const formName = 'editUser' + item.id
                      formsStore.createForm(formName, {
                        modalComponent: 'UserEditModal',
                        form: {
                          data: item,
                        },
                        props: {
                          onSuccess: () => httpStore.fetchRequest(...usersArgs, {
                            cacheTime: 0,
                          }),
                        },
                      })
                    }}
                  >
                    Редактировать
                  </Button>
                </DropDownList>
              ),
            },
          ]}
        />
      </div>
    </div>
  )
}

export default observer(Root)
