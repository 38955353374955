import { memo } from 'react'
import classNames from 'classnames'

import styles from './index.module.css'

const Label = memo(({
  className,
  disabled = false,
  label,
  onClick,
}) => {
  if (!label) return ''
  return <div
    className={classNames(
      styles.root,
      onClick && styles.clicked,
      disabled && styles.disabled,
      className
    )}
    onClick={disabled || !onClick ? undefined : onClick}
  >
    {label}
  </div>
})

export default Label
