import { useContext } from 'react'

import { StoreContext } from '@/store'

import Modal from '@/components/Modal'
import Input from '@/components/Input'
import Select from '@/components/Select'
import { BUTTON_TYPES } from '@/components/Button'

import styles from './index.module.css'


const DivisionEditModal = ({ children, onClose, form, onSuccess, ...other }) => {
  const divisionId = form.get('data.id')
  const parentDivision = form.get('data.parentDivision.id')

  const store = useContext(StoreContext)

  const { httpStore } = store

  const divisionsArgs = [
    'listDivisions',
    ['id', 'name' ],
    undefined,
  ]

  const divisions = httpStore.fetchRequest(...divisionsArgs).get('data', [])
    .filter(d => d.id !== divisionId)

  return (
    <Modal
      title="Подразделение"
      type="center"
      className={styles.root}
      width={460}
      onClose={onClose}
      buttons={[
        {
          type: BUTTON_TYPES.border,
          children: 'Отменить',
          onClick: onClose,
        },
        {
          children: divisionId ? 'Изменить' : 'Сохранить',
          disabled: form.hasErrors,
          onClick: () => form.submit(
            'upsertDivision',
            undefined,
            data => {
              return {
                division: {
                  id: data.id,
                  name: data.name,
                  parentDivisionId: data.parentDivision?.id,
                },
              }
            },
          )
            .then(() => {
              onClose()
              if (onSuccess) onSuccess()
            })
            .catch(console.log),
        },
      ]}
      {...other}
    >
      <Input
        label="Наименование*"
        placeholder="Наименование"
        required
        value={form.get('data.name', '')}
        error={form.get('errors.name', '')}
        onChange={form.set('data.name')}
        onError={form.set('errors.name')}
      />
      <Select
        label="Старшее подразделение"
        placeholder="Не выбрано"
        options={divisions.map(item => ({ value: item.id, label: item.name }))}
        values={parentDivision ? [parentDivision] : []}
        error={form.get('errors.parentDivision.id', '')}
        onChange={v => form.set('data.parentDivision.id')(v[0])}
        onError={form.set('errors.parentDivision.id')}
      />
    </Modal>
  )
}

export default DivisionEditModal
