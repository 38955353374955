import classNames from 'classnames'
import { useContext, useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Outlet } from 'react-router'
import { useNavigate, useLocation } from 'react-router-dom'

import { StoreContext } from '@/store'

import PageNav from '@/components/PageNav'

import pageStyles from '@/styles/page.module.css'
import styles from './index.module.css'


const Modules = () => {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const store = useContext(StoreContext)
  const { httpStore } = store

  const [first, setFirst] = useState()
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    if (loaded && pathname === '/modules' && first?.id) {
      navigate(`/modules/${first.id}`)
    }
  }, [pathname, first, navigate, loaded])

  const modulesArgs = [
    'listModules',
    ['id', 'name'],
    undefined,
    {
      onSuccess: (val) => {
        if (val.loaded && !first) {
          setFirst(val.get('data', [])[0])
          setLoaded(true)
        }
      },
    },
  ]
  const modules = httpStore.fetchRequest(...modulesArgs).get('data', [])

  if (loaded && modules.length < 1) {
    return (
      <div className={pageStyles.page}>
        <div className={pageStyles.header}>
          <h1 className={pageStyles.title}>
            Модули
          </h1>
        </div>
        <div className={pageStyles.block}>
          Модули не найдены
        </div>
      </div>
    )
  }

  return (
    <div className={classNames(
      pageStyles.page,
      styles.root
    )}>
      {modules.length > 0 && <PageNav links={modules}/>}
      <Outlet/>
    </div>
  )
}

export default observer(Modules)
