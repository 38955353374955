import { types, getSnapshot } from 'mobx-state-tree'

import { getDeepMap } from '../helpers/types'
import { callApi, getApiUrl } from '../helpers/fetch'
import { toGraphBody, toGraphQuery } from '../helpers/graphql'


let store

const url = getApiUrl()

import('../index').then(module => {
  store = module.getStore()
})

const HttpQuery = types.model('GetQuery', {
  callTime: types.number,
  data: getDeepMap(10),
  status: types.number,
  loaded: types.boolean,
}).views(self => ({
  get(path = '', defaultValue) {
    const pathArray = path.split('.')
    const value = path ? pathArray.reduce((memo, key, i) => {
      if (typeof memo?.get === 'function') return memo.get(key)
      if ((memo || {})[key]) return memo[key]
      return undefined
    }, self.data) : self.data
    try {
      return getSnapshot(value)
    } catch {
      return value || defaultValue
    }
  },
})).actions(self => ({
  setLoaded(loaded) {
    self.loaded = loaded
  },
  setCallTime(callTime) {
    self.callTime = callTime
  },
  setData(data) {
    self.data = data
  },
  setStatus(status = 0) {
    self.status = status
  },
}))

const HttpStore = types.model('store', {
  httpData: types.map(HttpQuery),
}).views(self => ({
  get token() {
    return store?.authStore?.token
  },
})).actions(self => ({
  fetchRequest(method, fields = [], props, { headers, cacheTime = 5000, onSuccess, ...options } = {}) {
    const query = JSON.stringify({
      query: `query { ${method}${
        props ? `(${toGraphBody(props)})` : ''
      } { ${toGraphQuery(fields)} } }`,
    })

    if (self.httpData.has(query)) {
      const prev = self.httpData.get(query)
      if (Date.now() - prev.callTime <= cacheTime) {
        if (onSuccess) {
          onSuccess(prev)
        }
        return prev
      }
    } else {
      self.httpData.set(query, {
        callTime: Date.now(),
        status: 0,
        data: {},
        loaded: false,
      })
    }

    const httpData = self.httpData.get(query)

    httpData.setCallTime(Date.now())
    httpData.setLoaded(false)

    const { token } = self
    const myHeaders = {
      ...headers,
      'Content-Type': 'application/json',
    }
    if (token) {
      myHeaders.Authorization = token
    }

    callApi(url, {
      ...options,
      body: query,
      method: 'POST',
      headers: myHeaders,
    })
      .then(response => {
        httpData.setLoaded(true)
        httpData.setData({ data: response?.data?.data?.[method] })
        httpData.setStatus(response?.status)
        if (onSuccess) {
          onSuccess(httpData)
        }
      })
      .catch(error => {
        console.error(error)
        httpData.setLoaded(true)
        httpData.setData(null)
        httpData.setStatus(error?.status)
        if (error?.status === 401 && store?.authStore?.logout) {
          return store?.authStore?.logout(link => {
            window.location.href = link
          })
        }
      })

    return httpData
  },
}))

export default HttpStore
