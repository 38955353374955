/* eslint-disable max-len */
import React from 'react'

const Delete = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    viewBox="0 0 16 16"
    {...props}
  >
    <path d="M15 1L1 15"/>
    <path d="M1 1L15 15"/>
  </svg>
)

export default Delete
