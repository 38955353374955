import { NavLink } from 'react-router-dom'
import classNames from 'classnames'

import styles from './index.module.css'

const PageNav = ({
  className,
  links = [],
}) => {
  if (links.length < 1) return ''
  return <nav
    className={classNames(
      styles.root,
      className
    )}>
    {links.map(item => (
      <NavLink
        key={item.id}
        to={item.id}
        className={({ isActive }) =>
          classNames(styles.link, isActive && styles.active)
        }
      >
        {item.name}
      </NavLink>
    ))}
  </nav>
}

export default PageNav
