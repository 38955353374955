import { observer } from 'mobx-react-lite'
import { useEffect, useContext, useState, useLayoutEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import CheckboxTree from 'react-checkbox-tree'

import Icon, { ICONS_TYPES, ROTATE_TYPES } from '@/components/Icon'
import Search from '@/components/Search'

import { StoreContext } from '@/store'

import pageStyles from '@/styles/page.module.css'
import styles from './index.module.css'

const dataTransform = (data = [], parentID = '', type = '') => {
  return data.map(item => {
    const nextParentID = parentID ? `${parentID}-${item.id}` : item.id
    const valuePrefix = type ? `${type}-` : ''
    const res = {
      value: `${valuePrefix}${item.id}`,
      label: item.name,
    }

    if (item.resources && item.resources.length > 1) {
      res.children = dataTransform(item.resources, nextParentID, 'resources')
    }

    if (item.mainFields && item.mainFields.length > 1) {
      res.children = dataTransform(item.mainFields, nextParentID, 'main')
    }

    if (item.additionalFields && item.additionalFields.length > 1) {
      res.children = dataTransform(item.additionalFields, nextParentID, 'additional')
    }

    return res
  })
}

const findAllParentPathsByLabel = (arr, targetLabel) => {
  const res = new Set()

  const search = (item, path) => {
    if (item.label.includes(targetLabel)) {
      path.forEach(value => res.add(value))
    }

    if (item.children) {
      for (let child of item.children) {
        search(child, path.concat(item.value))
      }
    }
  }

  for (let item of arr) {
    search(item, [])
  }

  return Array.from(res)
}

const Module = () => {
  const store = useContext(StoreContext)
  const { httpStore } = store
  const { moduleId } = useParams()
  const [checked, setChecked] = useState([])
  const [expanded, setExpanded] = useState([])

  const [search, setSearch] = useState('')
  const [notSearched, setNotSearched] = useState(false)
  const searchRef = useRef(null)

  const modulesArgs = [
    'viewModule',
    [
      'id',
      'name',
      {
        standards: [
          'id',
          'name',
          { resources: [
            'id',
            'name',
            {
              'additionalFields': [
                'id',
                'name',
                'unit',
              ],
            },
            {
              'mainFields': [
                'id',
                'name',
                'unit',
              ],
            },
          ] },
        ],
      },
    ],
    { 'moduleId': moduleId },
    {
      cacheTime : 1000 * 60 * 60,
    },
  ]
  const { get: getModules, loaded } = httpStore.fetchRequest(...modulesArgs)
  const module = getModules('data', [])

  const standards = []
  if (loaded && module.id && standards.length < 1) {
    standards.push({
      value: module.id,
      label: module.name,
      children: dataTransform(
        module.standards,
        module.id,
        'standards'
      ),
    })
  }

  useEffect(() => {
    if (loaded && moduleId) {
      setExpanded([moduleId])
      searchRef.current.reset()
      setChecked([])
    }
  }, [loaded, moduleId])

  useLayoutEffect(() => {
    const allSearched = document.querySelectorAll('span.' + styles.search)
    if (allSearched && allSearched.length > 0) {
      allSearched.forEach(element => {
        element.classList.remove(styles.search)
      })
    }

    if (search) {
      const titles = document.querySelectorAll('span.rct-title')
      const matchedElements = Array.from(titles).filter(title =>
        title.textContent.trim().includes(search)
      )

      if (matchedElements.length > 0) {
        matchedElements.forEach(title => {
          const parent = title.closest('span.rct-text')
          parent && parent.classList.add(styles.search)
        })

        const firstElementPosition = matchedElements[0].getBoundingClientRect().top + window.scrollY - 30
        window.scrollTo({ top: firstElementPosition, behavior: 'smooth' })
      }
    }
  }, [search])

  useEffect(() => {
    if (search && expanded.length < 1) {
      setNotSearched(true)
    } else {
      setNotSearched(false)
    }
  }, [search, expanded])

  const onSearch = (search) => {
    if (search) {
      setExpanded(findAllParentPathsByLabel(standards, search))
    } else {
      setExpanded([`${module.id}`])
    }
    setSearch(search)
  }

  if (!loaded) return null

  return (
    <div className={pageStyles.page}>
      <div className={pageStyles.header}>
        <h1 className={pageStyles.title}>
          Модуль - {module?.name}
        </h1>
      </div>
      <div className={pageStyles.block}>
        <div className={styles.header}>
          <h2 className={styles.title}>
            Standards
          </h2>
          <Search
            className={styles.search}
            ref={searchRef}
            onSearch={onSearch}
          />
        </div>
        {notSearched && <div className={styles['not-found']}>Не найдено</div>}
        <CheckboxTree
          id={styles['checkbox-tree']}
          nodes={standards}
          checked={checked}
          checkModel={'all'}
          expanded={expanded}
          onCheck={(checked) => setChecked(checked)}
          onExpand={(expanded) => setExpanded(expanded)}
          icons={{
            expandClose: <Icon
              className={styles.icon}
              type={ICONS_TYPES.arrowDown}
              rotate={ROTATE_TYPES.up}
            />,
            expandOpen: <Icon
              className={styles.icon}
              type={ICONS_TYPES.arrowDown}
              rotate={ROTATE_TYPES.down}
            />,
          }}
        />
      </div>
    </div>
  )
}

export default observer(Module)
