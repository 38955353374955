import { useState, useRef, useMemo, useCallback, useEffect } from 'react'
import classNames from 'classnames'

import useOnScrollToEnd from '@/utils/hooks/useOnScrollToEnd'
import useClickOutside from '@/utils/hooks/useClickOutside'

import Icon, { ICONS_TYPES, ROTATE_TYPES } from '@/components/Icon'
import Label from '@/components/Label'

import { EMPTY_VALUE_ERR_MSG } from '@/constants'
import { SELECT_TYPES, SELECT_LABEL_POSITION } from './constants'

import styles from './index.module.css'

let debounceTimeoutId = null

const Select = ({
  className,
  label,
  placeholder,
  error,
  multi = false,
  disabled = false,
  required = false,
  checkOnBlur = true,
  type = SELECT_TYPES.default,
  labelPosition = SELECT_LABEL_POSITION.top,
  values = [],
  options = [],
  isLoading,
  onSearch,
  onChange = () => {},
  onError = () => {},
  onScrollEnd = () => {},
  missingValueResolver = () => {},
}) => {
  const [searchValue, setSearchValue] = useState('')
  const [isOnBlur , setIsOnBlur] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const rootRef = useRef(null)
  const optionsRef = useRef(null)

  useOnScrollToEnd(optionsRef, () => {
    onScrollEnd()
  })

  useClickOutside(rootRef, () => {
    setIsOpen(false)
    if (onSearch) onSearch('')
  })

  useEffect(() => {
    if (required && values.length === 0) {
      onError(EMPTY_VALUE_ERR_MSG)
    } else {
      onError(undefined)
    }
  }, [onError, required, values])

  const toggleOpen = useCallback((value) => {
    if (!disabled) {
      const open = value === undefined ? !isOpen : value
      setIsOpen(open)
      open && setIsOnBlur(true)
      if (!open && onSearch) onSearch('')
    }
  }, [disabled, isOpen, onSearch])

  const onSelect = useCallback((option) => {
    const foundValue = values.find(item => item === option)
    let newValues = []

    if (multi) {
      newValues = foundValue
        ? values.filter(value => value !== option)
        : [...values, option]
    } else {
      newValues = foundValue ? [] : [option]
    }
    
    if (required && !newValues.length) {
      newValues = values
    }

    onChange(newValues)
    if (!multi) setIsOpen(false)
  }, [values, multi, required, onChange])


  const handleChange = useCallback((e) => {
    setSearchValue(e.target.value)

    clearTimeout(debounceTimeoutId)
    debounceTimeoutId = setTimeout(() => {
      onSearch(e.target.value)
    }, 500)
  }, [onSearch])

  const handleKeyDown = useCallback((e) => {
    if (e.key === 'Enter') {
      clearTimeout(debounceTimeoutId)
      onSearch(searchValue)
    }
  }, [onSearch, searchValue])

  const showValue = useMemo(() => {
    if (onSearch && isOpen) {
      return <input
        autoFocus
        type="text"
        onChange={handleChange}
        onKeyDown={handleKeyDown}
      />
    }
    if (values.length === 1) return options.find(o => o.value === values[0])?.label || missingValueResolver(values[0])
    if (!values.length && placeholder) return placeholder
    return values.length
  }, [onSearch, isOpen, values, options, missingValueResolver, placeholder, handleChange, handleKeyDown])

  const hasError = (error && !checkOnBlur) || (error && checkOnBlur && isOnBlur)

  return (
    <div
      className={classNames(
        styles.root,
        isOpen && styles.open,
        hasError && styles.error,
        values.length > 0 && styles.hasValue,
        disabled && styles.disabled,
        styles[labelPosition],
        styles[type],
        className
      )}
      ref={rootRef}
    >
      <Label
        label={label}
        disabled={disabled}
        required={required}
        onClick={() => toggleOpen(!isOpen)}
      />
      <div className={styles.select}>
        <div
          className={classNames(
            styles.value,
            values.length === 0 && styles.placeholder
          )}
          onClick={() => toggleOpen(!isOpen)}
        >
          <span className={styles.textValue}>{showValue}</span>
          <Icon
            className={styles.arrow}
            rotate={isOpen ? ROTATE_TYPES.down : ROTATE_TYPES.up}
            type={ICONS_TYPES.arrowDown}
            size={14}
          />
        </div>
        {isOpen && (
          <div className={styles.optionsWrapper}>
            <div
              ref={optionsRef}
              className={styles.options}
            >
              {!isLoading && options.length > 0 && options.map(option => {
                const isSelected = values.find(value => value === option.value)
                return (
                  <div
                    className={classNames(
                      styles.option,
                      isSelected && styles.selected
                    )}
                    key={option.value}
                    onClick={() => onSelect(option.value)}
                  >
                    {isSelected ? (
                      <Icon className={styles.mark} type={ICONS_TYPES.mark} size={10} />
                    ) : (
                      <div className={styles.mark}></div>
                    )}
                    {option.label}
                  </div>
                )
              })}
              {!isLoading && options.length === 0 &&
                <div className={styles.message}>Ничего не найдено</div>}
              {isLoading && <div className={styles.message}>Загрузка...</div>}
            </div>
          </div>
        )}
      </div>
      {hasError &&
        <div className={styles.errorContainer}>{error}</div>}
    </div>
  )
}

export default Select

export { SELECT_TYPES, SELECT_LABEL_POSITION } from './constants'
