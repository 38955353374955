import { types } from 'mobx-state-tree'


export const simpleTypes = [
  types.string,
  types.number,
  types.boolean,
  types.null,
  types.undefined,
]

export const getDeepMap = (deep = 0) => {
  if (deep === 0) return types.union(...simpleTypes, types.frozen())
  return types.union(
    ...simpleTypes,
    types.array(getDeepMap(deep - 1)),
    types.map(getDeepMap(deep - 1)),
  )
}
