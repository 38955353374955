export const EMPTY_VALUE_ERR_MSG = 'Значение обязательно'

export const USER_TOKEN_NOT_FOUND = 'Пользователь с таким токеном не найден.'

export const INCORRECT_PHONE_FORMAT = 'Некорректный номер телефона'

export const INCORRECT_MAIL_FORMAT = 'Некорректный E-Mail'

export const INCORRECT_MAIL_OR_PASSWORD = 'Неверный E-Mail пользователя или пароль'

export const ROLE_DICT = {
  ADMIN: 'Администратор',
  SENIOR_MANAGER: 'Старший менеджер',
  MANAGER: 'Менеджер',
}
