import { Outlet } from 'react-router'
import { observer } from 'mobx-react-lite'
import { useContext } from 'react'
import classNames from 'classnames'

import { StoreContext } from '@/store'

import Header from './Header'

import styles from './index.module.css'

const Root = () => {
  const store = useContext(StoreContext)

  const { layoutStore } = store

  const { type } = layoutStore.props

  return (
    <div className={classNames(styles.root, styles[type])}>
      {type === 'main' && (
        <Header className={styles.header} />
      )}
      <div className={styles.content}>
        <Outlet/>
      </div>
    </div>
  )
}

export default observer(Root)
