import { useEffect } from 'react'
import ReactModal from 'react-modal'
import classNames from 'classnames'

import Button from '../Button'

import styles from './index.module.css'


ReactModal.setAppElement('#root')

const Modal = ({
  className,
  title,
  titleClassName,
  overlayClassName,
  type = 'center',
  width = 400,
  height,
  style,
  children,
  onClose = () => {},
  buttons,
  afterClose,
  ...other
}) => {
  useEffect(() => {
    const prevOverflow = document.body.style.overflow
    document.body.style.overflow = 'hidden'
    return () => document.body.style.overflow = prevOverflow
  }, [])

  useEffect(() => () => {
    if (typeof afterClose === 'function') afterClose()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ReactModal
      {...other}
      id={undefined}
      onRequestClose={onClose}
      className={classNames(className, styles.modal, styles[type])}
      overlayClassName={classNames(overlayClassName, styles.overlay, styles[type])}
      style={{
        content: {
          width: width === 'unset' || type === 'full' ? undefined : width,
          height: height === 'unset' || type === 'full' ? undefined : height,
          ...style,
        },
      }}
      isOpen
      contentLabel="Minimal Modal Example"
    >
      {title && (
        <h2 className={classNames(styles.title, titleClassName)}>{title}</h2>
      )}
      {children}
      {buttons && (
        <div className={styles.buttons}>
          {buttons.map((props, i) => (
            <Button key={i} {...props} />
          ))}
        </div>
      )}
    </ReactModal>
  )
}

export default Modal
