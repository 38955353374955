import { createElement } from 'react'

import classNames from 'classnames'

import {
  ICON_COMPS,
  LABEL_POSITION_TYPES,
  ROTATE_TYPES,
  ROTATE_VALUES,
} from './constants'

import styles from './index.module.css'

const Icon = ({
  size,
  width,
  height,
  color,
  type,
  className,
  rotate = ROTATE_TYPES.up,
  label,
  labelPosition = LABEL_POSITION_TYPES.right,
  disabled = false,
  onClick,
  ...other
}) => {

  const inlineStyle = {
    transform: `rotate(${typeof rotate === 'string' ? ROTATE_VALUES[rotate] : rotate}deg)`,
    width: width || size,
    height: height || size,
  }

  return (
    <div {...{
      className: classNames(
        styles.root,
        className,
        labelPosition === LABEL_POSITION_TYPES.down || labelPosition === LABEL_POSITION_TYPES.up ?
          styles['root-column'] : '',
      ),
      onClick: disabled ? undefined : onClick,
      style: {
        cursor: onClick && 'pointer',
        color,
      },
    }} >
      <div {...{
        className: styles.svgWrapper,
        style: inlineStyle,
      }} >
        {!!type && createElement(ICON_COMPS[type], other)}
      </div>
      {label &&
        <div {...{
          className: styles[labelPosition],
        }}>
          {label}
        </div>
      }
    </div>
  )
}

export default Icon

export { ICONS_TYPES, ROTATE_TYPES, LABEL_POSITION_TYPES } from './constants'
