import { Navigate } from 'react-router'
import {
  Root,
  Login,
  Register,
  Users,
  Divisions,
  Modules,
  Module,
  NotFound,
  // Wip,
} from './pages'

import RouteWrapper from './components/RouteWrapper'

const routes = [{
  path: '/',
  element: <Root/>,
  children: [
    {
      path: 'login',
      element: <Login/>,
      available: authStore => !authStore.token,
      layoutProps: {
        type: 'auth',
      },
    },
    {
      path: 'register',
      element: <Register/>,
      available: authStore => !authStore.token,
      layoutProps: {
        type: 'auth',
      },
    },
    {
      path: 'users',
      element: <Users/>,
      available: authStore => authStore.token,
      layoutProps: {
        type: 'main',
      },
    },
    {
      path: 'divisions',
      element: <Divisions/>,
      available: authStore => authStore.token,
      layoutProps: {
        type: 'main',
      },
    },
    {
      path: 'modules',
      element: <Modules/>,
      available: authStore => authStore.token,
      layoutProps: {
        type: 'main',
      },
      children: [
        {
          path: ':moduleId',
          element: <Module/>,
          available: authStore => authStore.token,
          layoutProps: {
            type: 'main',
          },
        },
      ],
    },
  ],
}]

const filterRoutes = authStore => (memo, curr) => {
  const availableFunc = curr.available || (() => true)
  const available = availableFunc(authStore)
  if (!available) return memo
  const route = !curr.layoutProps ? curr : {
    ...curr,
    element: <RouteWrapper {...curr.layoutProps}>{curr.element}</RouteWrapper>,
  }
  if (!route.children) {
    return [...memo, route]
  } else {
    const children = route.children.reduce(filterRoutes(authStore), [])
    let redirect = children[0]?.path || ''
    redirect = /[:?*]/.test(redirect) ? '' : redirect
    if (redirect) {
      children.push({
        index: true,
        element: <Navigate to={redirect} replace/>,
      })
    }
    children.push({
      path: '*',
      element: <NotFound/>,
    })
    return [
      ...memo,
      {
        ...route,
        children,
      },
    ]
  }
}

const getRoutes = (authStore) => {
  return routes.reduce(filterRoutes(authStore), [])
}

export default getRoutes
