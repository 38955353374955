import { useContext } from 'react'
import { StoreContext } from '../../store'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router'

import { INCORRECT_MAIL_OR_PASSWORD } from '@/constants'

import Input from '@/components/Input'
import Button, { BUTTON_TYPES } from '@/components/Button'

import styles from './index.module.css'


const Login = () => {
  const navigate = useNavigate()
  const store = useContext(StoreContext)
  const { authStore } = store
  const form = authStore.form

  const error = form.get('errors.globalError')

  return (
    <div className={styles.rootWrapper}>
      <div className={styles.root}>
        <h1 className={styles.title}>Авторизация в Air</h1>
        <div className={styles.formWrapper}>
          <Input
            placeholder="Логин (e-mail)"
            type="mail"
            name="username"
            required
            value={form.get('data.email', '')}
            error={form.get('errors.email', '')}
            onChange={form.set('data.email')}
            onError={form.set('errors.email')}
          />
          <Input
            placeholder="Пароль"
            type="password"
            name="password"
            required
            value={form.get('data.password', '')}
            error={form.get('errors.password', '')}
            onChange={form.set('data.password')}
            onError={form.set('errors.password')}
          />
          {error &&
            <div className={styles.error}>
              {error === 'Incorrect username or password' ?
                INCORRECT_MAIL_OR_PASSWORD : error}
            </div>
          }
          <div className={styles.buttons}>
            <Button
              className={styles.login}
              type={BUTTON_TYPES.fill}
              disabled={form.hasErrors}
              onClick={() => authStore.login(navigate)}
            >
              Войти
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default observer(Login)
