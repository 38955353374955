import { useContext, useEffect } from 'react'
import { StoreContext } from '@/store/'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'

import { USER_TOKEN_NOT_FOUND } from '@/constants'

import Input from '@/components/Input'
import Button, { BUTTON_TYPES } from '@/components/Button'

import styles from './index.module.css'


const Register = () => {
  const navigate = useNavigate()
  const store = useContext(StoreContext)
  const { authStore } = store
  const form = authStore.form

  const [searchParams] = useSearchParams()
  const token = searchParams.get('token')
	
  const error = form.get('errors.globalError')

  useEffect(() => {
    form.set('data.registrationToken')(String(token))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={styles.rootWrapper}>
      <div className={styles.root}>
        <h1 className={styles.title}>Регистрация в Air</h1>
        <div className={styles.formWrapper}>
          <Input
            placeholder="ФИО"
            type="string"
            name="name"
            required
            value={form.get('data.name', '')}
            error={form.get('errors.name', '')}
            onChange={form.set('data.name')}
            onError={form.set('errors.name')}
          />
          <Input
            placeholder="Пароль"
            type="password"
            name="password"
            required
            value={form.get('data.password', '')}
            error={form.get('errors.password', '')}
            onChange={form.set('data.password')}
            onError={form.set('errors.password')}
          />
          {error &&
            <div className={styles.error}>
              {error === 'No user found with this token.' ?
                USER_TOKEN_NOT_FOUND : error}
            </div>
          }
          <div className={styles.buttons}>
            <Button
              className={styles.register}
              type={BUTTON_TYPES.fill}
              disabled={form.hasErrors}
              onClick={() => authStore.register(navigate)}
            >
              Зарегистрироваться
            </Button>
            <Button
              className={styles.login}
              type={BUTTON_TYPES.text}
              disabled={form.hasErrors}
              link="/login"
            >
              Войти
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default observer(Register)
