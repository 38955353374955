import { types } from 'mobx-state-tree'

const LayoutStore = types
  .model('LayoutStore', {
    props: types.optional(types.frozen(), {}),
  }).actions(self => ({
    set(value = {}) {
      self.props = value
    },
  }))

export default LayoutStore
