import { observer } from 'mobx-react-lite'
import { useContext } from 'react'
import { StoreContext } from '../store'

const context = require.context('./', true, /\.js$/)

const modalsComponents = context.keys().reduce((memo, key) => {
  const modalName = (key.match(/\.\/(.*)\/index.js/) || [])[1]
  if (modalName) {
    return {
      ...memo,
      [modalName]: observer(context(key).default),
    }
  }
  return memo
}, {})

const ModalsComponent = () => {
  const store = useContext(StoreContext)
  const { formsStore = {} } = store
  const { forms = {} } = formsStore
  return Array.from(forms.keys()).map((key) => {
    const fs = forms.get(key)
    if (fs.modalComponent) {
      const Modal = modalsComponents[fs.modalComponent]
      if (Modal) {
        return <Modal
          {...fs.props}
          form={fs.form}
          key={key}
          onClose={() => formsStore.removeForm(key)}
        />
      }
    }
    return null
  })
}

export default observer(ModalsComponent)
