/* eslint-disable max-len */
import React from 'react'

const Search = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    viewBox="0 0 16 16"
    {...props}
  >
    <path d="M1.89093 1.8901C3.1509 0.630286 4.80232 0 6.45314 0C8.10393 0 9.7553 0.630235 11.0154 1.8901C12.2753 3.14991 12.905 4.80112 12.905 6.45174C12.905 7.97074 12.3714 9.48974 11.3049 10.7042L16 15.4L15.4 16L10.7042 11.3041C9.48961 12.3705 7.97099 12.9041 6.45188 12.9041C4.80109 12.9041 3.14972 12.2738 1.88967 11.014C0.629692 9.75415 0 8.10294 0 6.45233C0 4.80171 0.630315 3.15059 1.88967 1.89069L1.89093 1.8901ZM6.45314 0.848701C5.01882 0.848701 3.58521 1.39604 2.49106 2.48941C1.39755 3.58344 0.850139 5.01696 0.850139 6.451C0.850139 7.88513 1.39755 9.31924 2.49106 10.4126C3.58523 11.5059 5.01892 12.0533 6.45314 12.0533C7.88736 12.0533 9.32175 11.5059 10.4152 10.4126C11.5087 9.31855 12.0561 7.88436 12.0561 6.451C12.0561 5.01686 11.5087 3.58275 10.4152 2.48941C9.32106 1.39604 7.88736 0.848701 6.45314 0.848701Z" stroke="none"/>
  </svg>
)

export default Search
