import React from 'react'
import ReactDOM from 'react-dom/client'
import './styles/index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'


import { getStore, StoreContext } from './store'

dayjs.extend(utc)

const root = ReactDOM.createRoot(document.getElementById('root'))

const store = getStore()

root.render(
  <React.StrictMode>
    <StoreContext.Provider value={store}>
      <App/>
    </StoreContext.Provider>
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
