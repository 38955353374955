export const BUTTON_TYPES = {
  text: 'text',
  border: 'border',
  dashed: 'dashed',
  fill: 'fill',
}

export const BUTTON_COLORS = {
  main: 'color-main',
  blue: 'color-blue',
  red: 'color-red',
}
