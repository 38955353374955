import { types } from 'mobx-state-tree'

import { Form } from '../FormStore'


const User = types.model('User', {
  id: types.maybeNull(types.string),
  email: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
})

const AuthStore = types
  .model('AuthStore', {
    user: types.optional(User, {}),
    token: types.maybeNull(types.string),
    form: types.optional(Form, {}),
  })
  .actions(self => ({
    setToken(token) {
      self.token = token
    },
    setUser(user) {
      self.user = user
    },
    login(navigate) {
      self.form.submit('login', ['id', 'token', 'email', 'name' ])
        .then(res => {
          const { token, ...user } = res?.data?.data?.login || {}
          if (!token) return Promise.reject(res)

          self.setToken('Bearer ' + token)
          self.setUser(user)
          if (typeof navigate === 'function') navigate('/')
        })
        .catch(() => {})
    },
    register(navigate) {
      self.form.submit('register', ['token'])
        .then(res => {
          const { token, ...user } = res?.data?.data?.register || {}
          if (!token) return Promise.reject(res)

          self.setToken('Bearer ' + token)
          self.setUser(user)
          if (typeof navigate === 'function') navigate('/')
        })
        .catch(() => {})
    },
    logout(navigate) {
      self.setToken(null)
      self.setUser({})
      if (typeof navigate === 'function') navigate('/')
    },
  }))

export default AuthStore
